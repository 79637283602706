import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"

export default function Box8() {
    return (
        <Layout>
            <div className="width-container">
                <div className="single-project-container">
                    <h1>Box8</h1>
                    <ul className="project-header">
                        <li>
                            <span className="label">Role</span>
                            <span className="role">Frontend Development</span>
                        </li>
                        <li>
                            <span className="label">Year</span>
                            <span className="role">2018-2019</span>
                        </li>
                        <li>
                            <span className="label">Team</span>
                            <span className="role">Box8 web team</span>
                        </li>
                    </ul>
                    <div className="single-project-content">
                        <p>
                            Box8 is a food tech company that delivers their
                            specialty in house prepared meals to customers. They
                            manage their own end-to-end experience.
                        </p>
                        <p>
                            I worked on a foray of new features and improvements
                            on the web platform.
                        </p>
                        <p>Selected key highlights &mdash;</p>
                        <ul>
                            <li>
                                &mdash; Worked on many major payment gateway
                                integrations such as Paytm, Paypal to name a few
                            </li>
                            <li>
                                &mdash; Worked on the live tracking view which
                                consisted of smooth animations and integrations
                                with maps
                            </li>
                            <li>
                                &mdash; Worked on migrating from Angular 1.x to
                                Angular 2+ versions along with a full redesign
                                of the web app
                            </li>
                            <li>
                                &mdash; Helped establish their first in house
                                design team
                            </li>
                        </ul>
                    </div>
                    <Link to="/work/enchanting-travels" className="read-btn">
                        Next:Enchanting travels &nbsp; &#187;
                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M 18.103516 6.9355469 C 17.920891 6.9164219 17.733047 6.94725 17.560547 7.03125 C 17.217547 7.19825 17 7.5476875 17 7.9296875 L 17 11.998047 L 4 11.998047 C 2.895 11.998047 2 12.893047 2 13.998047 L 2 15.998047 C 2 17.103047 2.895 17.998047 4 17.998047 L 17 17.998047 L 17 22.070312 C 17 22.453312 17.217547 22.80175 17.560547 22.96875 C 17.700547 23.03775 17.851 23.070312 18 23.070312 C 18.219 23.070313 18.436234 23.000328 18.615234 22.861328 L 27.685547 15.789062 C 27.928547 15.599063 28.070312 15.310953 28.070312 15.001953 C 28.070312 14.692953 27.928547 14.401891 27.685547 14.212891 L 18.615234 7.140625 C 18.464234 7.023125 18.286141 6.9546719 18.103516 6.9355469 z" /></svg> */}
                    </Link>
                </div>
            </div>
        </Layout>
    )
}
